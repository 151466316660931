<template>
  <main class="user__page-container">
    <div
      class="user__page"
      id="sayl-challenge-page-resto"
      v-if="!loading"
    ></div>

    <div
      class="user__loader"
      v-else>
      <ui-loader />
    </div>
  </main>
</template>

<script>
import { loadScript } from '@/helpers/loadscript.js'
import MixinChallenge from './mixin.js'

export default {
  name: 'ViewChallenges',

  mixins: [ MixinChallenge ],

  methods: {
    async reset() {
      try {
        this.loading = true
        
        await this.$challenge.getChallenges({})
        await this.$challenge.getSettings()
        
        if(this.isLogged) {
          await this.$challenge.getCompletions({})
        }
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
        setTimeout(() => loadScript(this.scriptUrl, this.scriptLoaded), 50)
      }
    },
  },
}
</script>

