import SaylStore from '@/bootstrap/stores'
import Config from '@/config'

export default {
  inject: [
    '$challenge',
    '$loyalty',
    '$user'
  ],

  data() {
    return {
      loading: true,
      history: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      challenge: null,
      challengeStore: SaylStore.challenge(),
    }
  },

  computed: {
    challenges() {
      return this.$challenge.challenges.map((v) => {
        return {
          completionsCount: v.completionsCount,
          description: v.description,
          summary: v.descriptionMini,
          from: v.from ? new Date(v.from).toISOString() : null,
          globalAmountRewards: v.globalAmountRewards,
          key: v.id,
          logo: this.$basil.get(v, 'images[0].url'),
          reward: v.rewardDescr,
          settings: v.rewardTypeSettings,
          title: v.name,
          to: v.to ? new Date(v.to).toISOString() : null,
          type: v.rewardType,
          userAmountRewards: v.userAmountRewards,
          userCompletionsCount: v.userCompletionsCount,
        }
      })
    },

    challengeUser() {
      return this.isLogged ?
        {
          firstname: this.$basil.get(this.user, 'firstname'),
          lastname: this.$basil.get(this.user, 'lastname'),
          points: this.$basil.get(this.user, 'balance.points'),
          historyHasMore: false,
          history: this.completions
        } :
        null
    },

    completions() {
      return (this.$challenge.completions || []).map(c => {
        let metadata = []
        if(c.voucher) {
          metadata.push({
            key: 'code',
            value: c.voucher.code
          })
        }

        if(c.credit) {
          metadata.push({
            key: 'credit',
            value: c.credit.amount.toFixed(2) + this.$basil.get(this.$loyalty, 'program.points.symbol', null)
          })
        }
        return {
          key: c.id,
          date: c.created,
          challenge: {
            type: c.challenge.reward_type,
            key: c.challenge.id,
            title: c.challenge.name,
          },
          metadata
        }
      })
    },

    dataset() {
      return {
        content: this.$basil.get(this.settings, 'pageContent'),
        points: this.$basil.get(this.settings, 'points'),
        rules: this.$basil.get(this.settings, 'rules'),
        style: this.$basil.get(this.settings, 'pageStyle'),
        challenge: this.value,
        user: this.challengeUser,
        challenges: this.challenges,
      }
    },

    hasChallenges() {
      return this.$basil.get(this.$challenge, 'active', false)
    },

    isLogged() {
      return this.user.anonymous === false
    },

    settings() {
      return this.$challenge.settings
    },

    scriptUrl() {
      return this.$basil.get(Config, 'challenge.page.url', 'https://cdn-apps.sayl.cloud/challenge-page/1.0.0/index.js')
    },

    user() {
      return this.$user.user
    },
  },

  methods: {
    getLabel(l) {
      let ret = l

      if(l === 'paid_order' || l === 'redeemed_credits' || l === 'subscribed_reward' || l === 'in_store_reward' || l === 'loyalty_trade') {
        ret = this.$t(`user-portal.user_balance_message_${l}`)
      }

      return ret
    },

    getMeta(h) {
      let ret = []

      if(h.message === 'loyalty_trade' && h.voucher != null) {
        ret.push({
          key: 'code',
          value: this.$basil.get(h, 'voucher.code', null)
        })
      }

      return ret
    },

    scriptLoaded() {
      this.challenge = window.SaylChallengePage.init(
        this.dataset,
        {
          context: document.getElementById('sayl-challenge-page-resto'),
          container: document.getElementById('sayl-challenge-page-resto'),
          shadow: false,
          hasHeight: false,
          hasBack: false,
        }
      )

      if(this.challenge) {
        if(this.$route.query.completion) {
          this.challenge.focus('entry_history', true, this.$route.query.completion)
        }

        this.challenge.registerCallback('challenge', (key, done) => {
          this.$router.push({ name: 'sayl-user-portal.challenge', params: { id: key } })
          done()
        })

        this.challenge.registerCallback('login', (done) => {
          this.$router.push({ name: 'sayl-user-portal.login' })
          done()
        })

        this.challenge.registerCallback('subscribe', (done) => {
          this.$router.push({ name: 'sayl-user-portal.signup' })
          done()
        })
      }
      this.iLoading = false
    },
  },

  mounted() {
    if(!this.hasChallenges) {
      this.$router.replace({ name: 'sayl-user-portal.home' }).catch(() => {})
      return
    }

    this.history = []
    this.pagination = {
      currentPage: 1,
      pageSize: 10,
      total: 0,
    },
    this.reset()
  },

  beforeDestroy() {
    const wait = (delay, action) => {
      setTimeout(() => this.challenge ? action() : wait(delay, action), delay)
    }

    const destroy = () => {
      this.challenge && this.challenge.unmount()

      let page = document.getElementById('sayl-challenge-page-resto')
      page && page.remove()

      let lpage = document.getElementById('sayl-challenge-page')
      lpage && lpage.remove()
    }

    this.challenge == null ? wait(100, destroy) : destroy()
  }
}
